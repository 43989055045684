import { useEffect, useState } from 'react';

import './App.css';
import HeadResources from './components/menu/Resources';
import MainMenu from './components/menu/Menu';
import Registration from './components/registration/Registration';
import Loading from './components/ui/Loading';

import { GetAccount, UpdateTimestamp, GetIslands, GetCommon } from './api/Api';

import MainContext from './context/Main';

export default function App() {

  const [triggerAccountCall, setTriggerAccountCall] = useState(0)
  const [triggerIslandCall, setTriggerIslandCall] = useState(0)
  const [triggerCommonCall, setTriggerCommonCall] = useState(0)


  const [loading, setLoading] = useState()
  const [accountStatus, setAccountStatus] = useState()

  const [account, setAccount] = useState()
  const [commonData, setCommonData] = useState()
  const [islandResource, setIslandResource] = useState(
    [
      {
        "resources": {
          "dooblons": 0,
          "settlers": 0,
          "slaves": 0,
          "food": 0,
          "timber": 0
        }
      }
    ]
  )
  const AppData = {
    account: account,
    island: islandResource[0],
    commonData: commonData,
    triggers: {
      // accountCall: () => setTriggerAccountCall(),
      // islandCall: () => setTriggerIslandCall()
      accountCall: triggerAccountCall,
      setAccountCall: setTriggerAccountCall,
      islandCall: triggerIslandCall,
      setIslandCall: setTriggerIslandCall
    }
  }


  let tg = window.Telegram.WebApp; //получаем объект webapp телеграма 
  tg.expand(); //расширяем на все окно  


  ////// API calls: //////////
  // Get Account Data from backend
  useEffect(() => {

    // Set load banner:
    setLoading(true)

    GetAccount()
      .then(response => {
        const status = response['status']
        const data = response['data']

        setAccountStatus(status)
        setAccount(data)

        // Remove load banner
        setLoading(false)

        // Send tp backend call, that user online
        UpdateTimestamp()
          .then(response => {
            const status = response['status']
            const data = response['data']

          })
          .catch(error => {
            console.error('Error updating timestamp:', error)
          })

      })
      .catch(error => {
        console.error('Error getting account:', error);
        window.location.replace('/ErrorConn.html');
        return;
      });

  }, [triggerAccountCall]);


  useEffect(() => {

    GetIslands("true")
      .then(response => {
        const status = response['status']
        const data = response['data']

        setIslandResource(data)

      })
      .catch(error => {
        console.error('Error. Can`t get Islands:', error);
        // Handle the error, e.g., show an error message
      });

  }, [triggerIslandCall]);


  useEffect(() => {

    GetCommon()
      .then(response => {
        // const status = response['status']
        const data = response['data']

        setCommonData(data)

      })
      .catch(error => {
        console.error('Error. Can`t get common data:', error);
        // Handle the error, e.g., show an error message
      });

  }, [triggerCommonCall]);
  ////////////////////////////





  return (
    <>

      {
        loading && <Loading />

      }

      {
        (!loading && accountStatus === 404) &&
        (
          <Registration />
        )
      }


      {
        (!loading && accountStatus === 401) &&
        (
          <p>
            401 Unauthorized
          </p>
        )
      }




      {
        (!loading && accountStatus === 200) && (


          <MainContext.Provider value={AppData}>
            <HeadResources islandResource={islandResource[0]} />
            <MainMenu />
          </MainContext.Provider>


        )
      }

      {
        (!loading && accountStatus === 400) &&
        (
          window.location.replace('/ErrorConn.html')
        )
      }


    </>
  )




}

