
import './Menu.css';
import { lang } from '../../data/language';
import { useState, useContext } from 'react';
import BuildField from './BuildField';
import MainContext from '../../context/Main';

export default function MainMenu() {

  const [currentPage, setCurrentPage] = useState('main')

  const [buildsFieldPage, setBuildsFieldPage] = useState('None')

  const AppData = useContext(MainContext);


  function handleMainButtons(props) {
    setCurrentPage(props)
    setBuildsFieldPage('None')
  }
  

  return (
    <div className='grid_main_menu'>


      <div id="button_main" className='button_main'>
        <img className='button_main_img'
          alt=""
          src='/ui/buttons/overview.png'
          onClick={() => handleMainButtons('main')}
        ></img>
      </div>


      <div id="button_buildings" className='button_main'>
        <img className='button_main_img'
          alt=""
          src='/ui/buttons/buildings.png'
          onClick={() => handleMainButtons('build')}
        ></img>
      </div>

      <div id="button_fleet" className='button_main'>
        <img className='button_main_img'
          alt=""
          src='/ui/buttons/ships.png'
          onClick={() => handleMainButtons('fleet')}
        ></img>
      </div>


      <div id='div_main' className='section_main'>


        {currentPage === 'main' &&
          <section className='text'>

            <img className='section_image' alt="" src={'/img/buildings/' + AppData.account.nation + '/main.png'}></img>

            <div id='div_main_top_text'>

              <h4>{lang.menu.body_text.greetings} {AppData.account.nickname} <img className='button_flag_16' alt="" src={'/ui/flags/flag_' + (AppData.account.nation).toLowerCase() + '.jpg'}></img></h4>
              <p>{lang.menu.body_text.line1} "{AppData.account.nation}" {lang.menu.body_text.line1_end}</p>
              <p>{lang.menu.body_text.line2}</p>
              <p>{lang.menu.body_text.line3}</p>
            </div>
          </ section>
        }


        {currentPage === 'build' &&
          <BuildField  
            buildsFieldPage={buildsFieldPage}         // Pass useState variable
            setBuildsFieldPage={setBuildsFieldPage}   // Pass useState function
            />
        }



        {currentPage === 'fleet' &&
          <section className='text'>

            <h4> {lang.under_development} </h4>
          </section>

        }





      </div>
    </div>

  );
}
