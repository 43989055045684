import { lang } from "../../data/language"
import './BuildField.css'
import { useState, useContext } from "react"
import BuildFieldInfo from "./BuildFieldInfo"
import MainContext from '../../context/Main';


export default function BuildField(param) {

    const buildings_list = ['homes', 'plantations', 'timber']
    const AppData = useContext(MainContext);


    const active_box = {
        field: "build_field",
        text: "build_field_text",
        image: "build_field_image"
    }

    const disable_box = {
        field: "build_field_hover",
        text: "build_field_text_hover",
        image: "build_field_image_hover"
    }


    const [boxClassName, setBoxClassName] = useState(
        {
            homes: active_box,
            plantations: active_box,
            timber: active_box
        }
    )

    function handleMouseEnter(prop) {
        setBoxClassName({
            ...boxClassName,
            [prop.build_name]: disable_box
        })
    }

    function handleMouseLeave(prop) {
        setBoxClassName({
            ...boxClassName,
            [prop.build_name]: active_box
        })
    }


    return (
        <section className="text">
            {param.buildsFieldPage === 'None' &&

                buildings_list.map(build_name => (

                    < div key={build_name}
                        className={boxClassName[build_name].field}

                        onMouseEnter={() => { handleMouseEnter(build_name={build_name}) }}
                        onTouchStart={() => { handleMouseEnter(build_name={build_name}) }}

                        onMouseLeave={() => { handleMouseLeave(build_name={build_name}) }}
                        onTouchEnd={() => { handleMouseLeave(build_name={build_name}) }}

                        onClick={() => param.setBuildsFieldPage(build_name)}
                    >
                        <img className={boxClassName[build_name].image} alt="" src={'/img/buildings/' + AppData.account.nation + '/' + build_name + '.png'}></img>
                        <h4 className={boxClassName[build_name].text}>{lang.buildings[build_name].name}</h4>
                        <p className={boxClassName[build_name].text}>{lang.buildings[build_name].description}</p>
                    </div >
                ))

            }


            {param.buildsFieldPage !== 'None' &&
                <BuildFieldInfo buildsFieldPage={param.buildsFieldPage} />
            }


        </section>


    )
}