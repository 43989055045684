// import { lang } from '../../data/language'
// import './BuildProgress.css'


export function BuildProgress({ start, completed, ...props }) {

    return (
        <div className="build_in_progress">
            <p className='build_field_text'>Construction in progress</p>
            <p className='build_field_text'>Please wait::: from {start} to {completed}</p>
            <p className='build_field_text'>Time now:  {Math.round(Date.now() / 1000)}</p>
        </div>
    );
}
