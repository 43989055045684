


import './Registration.css';

import { lang } from '../../data/language'
import { ButtonContinue } from '../ui/Buttons'
import { useEffect, useState } from 'react';

import { PopUpMessage } from '../../modal/ModalReg/PopUpMessage';

import { IsNickNameExist, IsIslandExist, Register } from '../../api/Api';


export default function Registration() {
    const [formReg, setFormReg] = useState(
        {
            nickname: '',
            validationError: false,
            islandName: '',
            nation: ''
        }
    )

    const [notificationNickNotCorrect, setNotificationNickNotCorrect] = useState(false)
    const [notificationIslandNotCorrect, setNotificationIslandNotCorrect] = useState(false)
    const [notificationRegistrationError, setNotificationRegistrationError] = useState(false)
    const [regStep, setRegStep] = useState(1)


    const [callCheckNickname, setCallCheckNickname] = useState(0)
    const [callCheckIslandName, setCallCheckIslandName] = useState(0)
    const [callRegistration, setCallRegistration] = useState(0)





    useEffect(() => {


            if (regStep === 1 && formReg.nickname.trim().length > 0) {
                IsNickNameExist(formReg.nickname)
                    .then(response => {

                            const status = response['status']
                            const data = response['data']

                            // console.log('response:' + response);
                            // console.log('status:' + status);
                            // console.log('data:' + data);


                            if (status === 200 && data) {
                                setNotificationNickNotCorrect(true);
                            } else {
                                setRegStep(2);
                            }

                    })


                    .catch(error => {
                        console.error('Error checking nickname:', error);
                        // Handle the error, e.g., show an error message
                    });
            }

        


    }, [callCheckNickname]);




    useEffect(() => {
        if (regStep === 2 && formReg.islandName.trim().length > 0) {
            IsIslandExist(formReg.islandName)
                .then(response => {
                    const status = response['status']
                    const data = response['data']

                    if (status === 200 && data) {
                        setNotificationIslandNotCorrect(true);
                    } else {
                        setRegStep(3);
                    }
                })
                .catch(error => {
                    console.error('Error checking island name:', error);
                    // Handle the error, e.g., show an error message
                });
        }
    }, [callCheckIslandName]);



    useEffect(() => {
        if (regStep === 3) {
            // Perform registration logic here
            console.log('Registration data:', formReg);

            Register(
                formReg.nickname,
                formReg.islandName,
                formReg.nation
            )
                .then(response => {
                    const status = response['status']
                    // const data = response['data']

                    if (status !== 201) {
                        setNotificationRegistrationError(true);
                    } else {
                        setRegStep(4);
                    }
                })
                .catch(error => {
                    console.error('Error during registration:', error);
                    // Handle the error, e.g., show an error message
                });
        }
    }, [callRegistration]);



    function handleNickRegistration() {
        const error = formReg.nickname.trim().length === 0;

        setFormReg({
            ...formReg,
            validationError: error
        })
        if (!error) {
            setCallCheckNickname(callCheckNickname + 1)

        } else {
            setNotificationNickNotCorrect(true)

        }
    }

    function handleIslandRegistration() {
        const error = formReg.islandName.trim().length === 0;

        setFormReg({
            ...formReg,
            validationError: error
        })
        if (!error) {
            setCallCheckIslandName(callCheckIslandName + 1)

        } else {
            setNotificationIslandNotCorrect(true)

        }
    }


    function handleNationRegistration(selected_nation) {
        setFormReg({
            ...formReg,
            nation: selected_nation,
            validationError: false
        })
        setCallRegistration(callRegistration + 1)
    }




    return (
        <div className='registration_body'>
            {
                PopUpMessage({
                    in_open: notificationNickNotCorrect,
                    in_onClick: () => setNotificationNickNotCorrect(false),
                    title: lang.registration.popIncorrectNickname.title,
                    message: lang.registration.popIncorrectNickname.message
                })
            }
            {
                PopUpMessage({
                    in_open: notificationIslandNotCorrect,
                    in_onClick: () => setNotificationIslandNotCorrect(false),
                    title: lang.registration.popIncorrectIslandName.title,
                    message: lang.registration.popIncorrectIslandName.message
                })
            }
            {
                PopUpMessage({
                    in_open: notificationRegistrationError,
                    in_onClick: () => setNotificationRegistrationError(false),
                    title: lang.registration.popErrorRegistration.title,
                    message: lang.registration.popErrorRegistration.message
                })
            }


            {regStep === 1 &&
                <section className='registration_form'>
                    <p className='text'>
                        {lang.registration.message_greetings}
                    </p>

                    <label className='text'>
                        {lang.registration.message_ask_nickname}
                    </label>
                    <input className='registration_input'
                        name='nickname'
                        type='text'
                        style={{ borderColor: formReg.validationError ? 'red' : '' }}
                        value={formReg.nickname}
                        placeholder={lang.registration.message_nickname_placeholder}
                        onChange={event =>
                            setFormReg((prev) => ({ ...prev, nickname: event.target.value, validationError: false }))
                        }>

                    </input>

                    <ButtonContinue disabled={formReg.validationError} id='reg_nickname_button' onClick={handleNickRegistration} />
                </section>
            }




            {regStep === 2 ?
                <section className='registration_form'>
                    <p className='text'>
                        {lang.registration.message_greetings_2} <strong>{formReg.nickname}</strong>
                    </p>

                    <label className='text'>
                        {lang.registration.message_ask_island_name}
                    </label>
                    <input className='registration_input'
                        name='island_name'
                        type='text'
                        style={{ borderColor: formReg.validationError ? 'red' : '' }}
                        value={formReg.islandName}
                        placeholder={lang.registration.message_island_name_placeholder}
                        onChange={event =>
                            setFormReg((prev) => ({ ...prev, islandName: event.target.value, validationError: false }))}
                    >

                    </input>
                    <ButtonContinue disabled={formReg.validationError} id='reg_island_button' onClick={handleIslandRegistration} />
                </section>
                : null}
            {regStep === 3 ?
                <section className='registration_form'>
                    <p className='text'>
                        <strong>{formReg.nickname}</strong>, {lang.registration.message_greetings_3}
                    </p>

                    <label className='text'>
                        {lang.registration.message_ask_nation} <strong>{formReg.islandName}</strong>
                    </label>
                    <br />
                    <img className='button_flag' src='/ui/flags/flag_england.jpg' alt='British' onClick={() => handleNationRegistration('England')}></img>
                    <img className='button_flag' src='/ui/flags/flag_france.jpg' alt='France' onClick={() => handleNationRegistration('France')}></img>
                    <img className='button_flag' src='/ui/flags/flag_pirates.jpg' alt='Pirates' onClick={() => handleNationRegistration('Pirates')}></img>
                    <img className='button_flag' src='/ui/flags/flag_holland.jpg' alt='Holland' onClick={() => handleNationRegistration('Holland')}></img>
                    <img className='button_flag' src='/ui/flags/flag_spain.jpg' alt='Spain' onClick={() => handleNationRegistration('Spain')}></img>
                </section>
                : null}




            {regStep === 4 ?
                <section className='registration_form'>
                    <p className='text'>
                        {lang.registration.message_finish} <strong>{formReg.nickname}</strong>!
                    </p>
                    <ButtonContinue id='reg_finish_button' onClick={() => window.location.replace('/')} />
                </section>
                : null}





            {/* just for debugg */}
            {/* <pre>
                {JSON.stringify(formReg, null, 2)}
            </pre> */}


        </div>
    )

}
