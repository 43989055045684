import './Resources.css';


export default function HeadResources(param) {




  return (
    <>
      <section className='head_resources_section text' width="10%">

            <div className='head_resources'>
              <img className='res_image' src='/ui/resources/dooblons.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.dooblons)}</div>
              <img className='res_image' alt="" src='/ui/resources/citizen.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.settlers)}</div>
              <img className='res_image' alt="" src='/ui/resources/slave.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.slaves)}</div>
              <img className='res_image' alt="" src='/ui/resources/food.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.food)}</div>
              <img className='res_image' alt="" src='/ui/resources/wood.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.timber)}</div>

            </div>

            <div className='head_resources '>

              <b>[{param.islandResource.name}]</b>

              <img className='res_image' alt="" src='/ui/resources/metal.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.metal)}</div>
              <img className='res_image' alt="" src='/ui/resources/gunpowder.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.gunpowder)}</div>
              <img className='res_image' alt="" src='/ui/resources/sailcloth.png'></img>
              <div className='head_resources_block'> {Math.round(param.islandResource.resources.sailcloth)}</div>

            </div>


      </section >



    </>
  );
}

// app/public/ui/resources/money-bag.png