

export function DoneOk(prop) {

    return (
        <>
            {prop.visible && (
                <img className=''
                    alt=""
                    src='/ui/others/done_32.png'
                ></img>
            )}
        </>
    )
}